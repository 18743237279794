const I18NextHttpBackend = require('i18next-http-backend/cjs')
const { mergeWith } = require('lodash')

const csLocalJson = require('./public/locales/cs/common.json')

const i18nConfig = {
    i18n: {
        reloadOnPrerender: process.env.NODE_ENV === 'development',
        defaultLocale: 'cs',
        locales: ['cs'],
        backend: {
            loadPath: `${process.env.NEXT_PUBLIC_TRANSLATION_API}/translation/simplified`,
            customHeaders: {
                'Accept-Language': 'cs'
            },
            parse: data => {
                const remoteJson = JSON.parse(data)
                /*
                const isDevelopmentEnvironment = process.env.NODE_ENV === 'development'
                if (!isDevelopmentEnvironment) {
                    return remoteJson
                }
                 */
                return mergeWith({}, csLocalJson, remoteJson, (objValue, srcValue) => {
                    if (objValue === srcValue) {
                        return objValue
                    }
                })
            }
        }
    },
    use: [I18NextHttpBackend],
    serializeConfig: false,
    ns: ['common']
}

module.exports = i18nConfig
